// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/checkbox-input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/checkbox-input.tsx");
  import.meta.hot.lastModified = "1726716723001.0098";
}
// REMIX HMR END

import React from 'react';
import { useField } from 'remix-validated-form';
import { cn } from '~/lib/utils/utils';
export default function CheckboxInput({
  name,
  label,
  className
}) {
  _s();
  const {
    getInputProps
  } = useField(name);
  return <div className={cn(className, 'flex items-center space-x-1')}>
      <input type="checkbox" {...getInputProps({
      id: name
    })} />
      <label htmlFor={name} className="text-grey-900 pb-0">
        {label}
      </label>
    </div>;
}
_s(CheckboxInput, "EqbzM7BgR/6FijnMeb+tCqsaHSA=", false, function () {
  return [useField];
});
_c = CheckboxInput;
var _c;
$RefreshReg$(_c, "CheckboxInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;