// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/storage.service.ts"
);
import.meta.hot.lastModified = "1726716723018.01";
}
// REMIX HMR END

class StorageService<T> {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  public get(key: string): T | null {
    const storedValue = this.storage.getItem(key);
    return storedValue ? JSON.parse(storedValue) as T : null;
  }

  public set(key: string, value: T) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string) {
    this.storage.removeItem(key);
  }

  public clear() {
    this.storage.clear();
  }
}

export default StorageService;