// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/useWindowFocusRevalidator.ts"
);
import.meta.hot.lastModified = "1726716723005.0098";
}
// REMIX HMR END

import {useRevalidator} from '@remix-run/react';
import {useEffect} from 'react';

export function useWindowFocusRevalidator() {
  const revalidater = useRevalidator();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        revalidater.revalidate();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
}
