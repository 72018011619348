// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_public.login/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_public.login/route.tsx");
  import.meta.hot.lastModified = "1726716723017.01";
}
// REMIX HMR END

import { json, redirect } from '@remix-run/server-runtime';
import React from 'react';
import { validationError } from 'remix-validated-form';
import { v4 as uuidv4 } from 'uuid';
import { useWindowFocusRevalidator } from '~/hooks/useWindowFocusRevalidator';
import { LOCAL_STORAGE_KEYS } from '~/lib/constants/general.constant';
import { Routes } from '~/lib/constants/routes.constent';
import { createUserSession, getAccessToken } from '~/lib/utils/auth-session.server';
import { getMessageSession, messageCommitSession, setErrorMessage } from '~/lib/utils/toast-session.server';
import LoginForm, { LoginFormFieldValidator } from '~/routes/_public.login/login-form';
import { getCustomerStatus, verifyLogin } from '~/routes/_public.login/login.server';
import StorageService from '~/services/storage.service';
export const loader = async ({
  context
}) => {
  const accessToken = await getAccessToken(context);
  if (accessToken) {
    return redirect('/');
  }
  return json({});
};
export const action = async ({
  request,
  context
}) => {
  const messageSession = await getMessageSession(request);
  try {
    const result = await LoginFormFieldValidator.validate(await request.formData());
    if (result.error) {
      return validationError(result.error);
    }
    const {
      email,
      password,
      rememberMe
    } = result.data;
    const {
      accessToken
    } = await verifyLogin({
      email,
      password,
      context
    });
    if (!accessToken) return redirect(Routes.LOGIN);
    const customerData = await getCustomerStatus({
      context,
      accessToken
    });
    // Generate a unique session ID
    const sessionId = uuidv4();
    return createUserSession({
      request,
      accessToken,
      rememberMe,
      context,
      customerData,
      sessionId
    });
  } catch (error) {
    if (error instanceof Error) {
      setErrorMessage(messageSession, error.message);
      return json({
        error
      }, {
        headers: {
          'Set-Cookie': await messageCommitSession(messageSession)
        }
      });
    }
    return json({
      error
    }, {
      status: 400
    });
  }
};
export default function LoginPage() {
  _s();
  React.useEffect(() => {
    // Check if localStorage is available (client-side)
    if (typeof window !== 'undefined') {
      const storageService = new StorageService(); // Assuming StorageService is a class
      storageService.remove(LOCAL_STORAGE_KEYS.PERMISSIONS); // Remove permission from localStorage
    }
  }, []);
  useWindowFocusRevalidator();
  return <LoginForm />;
}
_s(LoginPage, "S8r85NXrLhIKgO6VXcZWfEw4+fo=", false, function () {
  return [useWindowFocusRevalidator];
});
_c = LoginPage;
var _c;
$RefreshReg$(_c, "LoginPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;